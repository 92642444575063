import './lib/STLLoader';
import './lib/GLTFLoader';
import './lib/OrbitControls';
import './lib/csg';
import './lib/THREE.CSG';
import './lib/THREEx.GeometryUtils';
import './lib/STLExporter';
import './lib/DragControls';
import opentype from './lib/opentype.min';
import './lib/TTFLoader';
import { ModifierStack, Bend } from './lib/modifier';

window.opentype = opentype;
window.ModifierStack = ModifierStack;
window.Bend = Bend;
