<template lang="pug">
  div.option-list
    div.option-list__heading(@click="$emit('onClick')")
      v-icon.dropdown-icon(
        name="chevron-down"
        :class="{'flip-y': flip}"
      )
      span {{ title }}
    div.option-list__container.shaft(
      :id="id"
      :class="{ hide: !flip }"
      :style="{ maxHeight: maxHeight}"
    )
      slot
</template>

<script>
export default {
  name: 'ConfigListItem',
  props: {
    id: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    // animation status of this container when clicked
    flip: {
      type: Boolean,
      default: false,
    },
    // maxHeight of this component
    maxHeight: {
      type: String,
      default: '100%',
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style src="./ConfigListItem.scss" lang="scss" scoped />
