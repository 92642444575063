<template lang="pug">
  .button-group
    button.button-group__item(
      :style="{ width: `calc(${100/cnt }% - 0.2em)` }"
      :class="{ active: selectedValue === value }"
      v-for="(value, name, index) in data"
      :key="value"
      @click="handleClick(value, index)"
    ) {{ name }}

</template>

<script>
export default {
  name: 'BaseButtonGroup',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedValue: this.selected,
    };
  },
  computed: {
    cnt() {
      return Object.keys(this.data).length || 1;
    },
  },
  methods: {
    handleClick(value) {
      this.selectedValue = value;
      this.$emit('select', value);
    },
  },
};
</script>

<style src="./BaseButtonGroup.scss" lang="scss" scoped />
