import { render, staticRenderFns } from "./BaseInput.vue?vue&type=template&id=61c5be59&scoped=true&lang=pug&"
import script from "./BaseInput.vue?vue&type=script&lang=js&"
export * from "./BaseInput.vue?vue&type=script&lang=js&"
import style0 from "./BaseInput.scss?vue&type=style&index=0&id=61c5be59&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c5be59",
  null
  
)

export default component.exports