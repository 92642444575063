import Vue from 'vue';
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/bars';
import 'vue-awesome/icons/angle-right';
import 'vue-awesome/icons/chevron-down';
import 'vue-awesome/icons/check';
import 'vue-awesome/icons/thumbs-up';
import 'vue-awesome/icons/money-bill-alt';
import 'vue-awesome/icons/print';
import 'vue-awesome/icons/shipping-fast';
import 'vue-awesome/icons/box-open';
// import 'vue-awesome/icons/cc-visa';
// import 'vue-awesome/icons/cc-mastercard';
// import 'vue-awesome/icons/cc-amex';

Vue.component('v-icon', Icon);
