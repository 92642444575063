<template lang="pug">
  div
    div.label {{ label }}
    input(
      :id="id"
      :class="{'full-width': fullWidth, [wrapperClass]: true}"
      :type="type"
      :placeholder="placeholder"
      :value="val"
      :min="min"
      :readonly="readOnly"
      @input="handleInput($event.target.value)"
      @keyup="handleKeyUp($event.target.value)"
    )
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    id: {
      type: String,
      default: null,
    },
    wrapperClass: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    min: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: [String, Number],
      default: undefined,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    onKeyUp: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      val: this.value,
    };
  },
  methods: {
    handleInput(v) {
      if (this.type === 'number') {
        if (v < this.min) {
          this.val = this.min;
        } else {
          this.val = v;
        }

        if (this.max !== undefined) {
          if (v > this.max) {
            this.val = this.max;
          } else {
            this.val = v;
          }
        }
      } else {
        this.val = v;
      }

      this.$emit('update:value', this.val);
    },
    handleKeyUp(v) {
      if (this.type === 'number') {
        if (v < this.min) {
          this.val = this.min;
        } else {
          this.val = v;
        }

        if (this.max !== undefined) {
          if (v > this.max) {
            this.val = this.max;
          } else {
            this.val = v;
          }
        }
      } else {
        this.val = v;
      }

      this.$emit('onKeyUp', this.val);
    },
  },
};
</script>

<style src="./BaseInput.scss" lang="scss" scoped />
