const TYPES = {
  SHAFT: 'SHAFT',
  THREAD: 'THREAD',
  PORES: 'PORES',
  SCALE: 'SCALE',
};

const PORE_SIZE = {
  SMALL: 0,
  MEDIUM: 1,
  LARGE: 2,
};

export {
  TYPES,
  PORE_SIZE,
};
