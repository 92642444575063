<template lang="pug">
  div.image-input
    base-button.scale-button(
      id="btn_background"
      label="Upload X-Ray"
      full-width
      outlined
      @onClick="openModal"
    )
    input.file-input(
      :id="id"
      ref="fileInput"
      type="file"
      @change="uploadFile"
    )
</template>

<script>
export default {
  name: 'BaseImageUpload',
  props: {
    id: {
      type: String,
      default: '',
    },
    update: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    openModal() {
      this.$refs.fileInput.click();
    },
    uploadFile() {
      this.$emit('update');
    },
  },
};
</script>

<style src="./BaseImageUpload.scss" lang="scss" scoped />
