<template lang="pug">
  div
    table
      thead
        tr
          td(v-for="(header, headerIndex) in headers" :key="headerIndex")
            | {{ header }}
      tbody
        tr(
          v-for="(field, fieldIndex) in fields"
          :key="fieldIndex"
          @click="handleClick(fieldIndex)"
        )
          td(v-for="(element, elementIndex) in field" :key="elementIndex")
            | {{ element }}
          td: v-icon(name="angle-right" scale="1.5")
        tr(
          v-if="loading"
        )
          td(:colspan="headers.length")
            div.loading-wrapper
              base-animation(name="loading-dark")
    div.mt-20.flex-center(v-if="!loading && hasMore")
      base-button(label="Load More", @onClick="handleLoadMore")
</template>

<script>
export default {
  name: 'BaseTable',
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    detailIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(index) {
      this.$emit('onClick', index);
    },
    handleLoadMore() {
      this.$emit('onLoadMore');
    },
  },
};
</script>

<style src="./BaseTable.scss" lang="scss" scoped />
