<template lang="pug">
  div(
    :class="{'full-width': fullWidth, 'card': true}"
  )
    div.title(v-if="title") {{ title }}
    slot/
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style src="./BaseCard.scss" lang="scss" scoped />
