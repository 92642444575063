import { PORE_SIZE } from './types';

const commonSlideOption = {
  dotSize: 32,
  height: 12,
  contained: true,
  min: 0,
  max: 100,
  adsorb: false,
  lazy: false,
  tooltip: 'always',
  dotStyle: {
    display: 'none',
  },
  railStyle: {},
  processStyle: {
    backgroundColor: '#2d3338',
  },
  tooltipStyle: {
    position: 'absolute',
    transform: 'translate(-50%, 50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #000000',
    backgroundColor: '#2d3338',
    height: '20px',
    width: '20px',
    cursor: 'pointer',
  },
};

const poreTypeLabels = {
  'S / 1.2': PORE_SIZE.SMALL,
  'M / 1.4': PORE_SIZE.MEDIUM,
  'L / 1.6': PORE_SIZE.LARGE,
};

const pxToCenRatio = 37.79527559055118;

export {
  commonSlideOption,
  poreTypeLabels,
  pxToCenRatio,
};
