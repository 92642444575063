import Vue from 'vue';

const requireComponent = require.context(
  './',
  true,
  /Base[A-Z]\w+\.(vue)$/,
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');

  Vue.component(
    componentName,
    componentConfig.default || componentConfig,
  );
});
