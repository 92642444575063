<template lang="pug">
  button(
    :class=`{
      'flex-center': true,
      'full-width': fullWidth,
      'outlined': outlined,
      'focused': focused,
    }`
    :id="id"
    :type="type"
    :disabled="busy"
    @click="handleClick"
  )
    template(v-if="busy")
      base-animation(name="loading")
    template(v-else)
      slot
      | {{ label }}
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    id: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'primary',
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style src="./BaseButton.scss" lang="scss" scoped />
