<template lang="pug">
  router-view
</template>

<script>
export default {
  name: 'App',
};
</script>
<style lang="scss">
@font-face {
  font-family: 'Font awesome 5 free 400';
  src: url('fonts/Font-Awesome-5-Free-Regular-400.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Font awesome 5 brands 400';
  src: url('fonts/Font-Awesome-5-Brands-Regular-400.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Font awesome 5 free solid 900';
  src: url('fonts/Font-Awesome-5-Free-Solid-900.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Orborn';
  src: url('fonts/Orborn.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
</style>
